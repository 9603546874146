.boxed-btn {
	background: #fff;
	color: $theme-color4;
	display: inline-block;
	padding: 18px 44px;
	font-family: $font1;
	font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid $theme-color4;
    letter-spacing: 3px;
    // width: 180px;
    text-align: center;
    color: $theme-color4 !important;
    text-transform: uppercase;
    cursor: pointer;
    &:hover{
        background: $theme-color4;
        color: #fff !important;
        border: 1px solid $theme-color4;
    }
    &:focus{
        outline: none;
    }
    &.large-width{
        width: 220px;
    }
}
.boxed-btn3 {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    padding: 17px 55px;
    font-family: $font1;
    display: inline-block;
    @extend  %btn_gradient;
    @include border-radius(30px);
    background-position: 100% 0;
    background-position: 0 0;
    &:hover{
        
        background-size: 200% 200%;
        color: #fff;
    }
    &:focus{
        outline: none;
    }
}
.boxed-btn4 {
    background:$white_color;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid transparent ;
    color: $theme-color4;
    padding: 17px 50px;
    font-family: $font1;
    display: inline-block;
    @include border-radius(30px);
    &:hover{
        background: $theme-color4;;
        color: $white_color;
        border: 1px solid  $theme-color4;
    }
    &:focus{
        outline: none;
    }
}
.boxed-btn2 {
	background: transparent;
	color: #fff;
	display: inline-block;
	padding: 18px 24px;
	font-family: $font1;
	font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    &:hover{
        background: #fff;
        color: #131313 !important;
    }
    &:focus{
        outline: none;
    }
}
.line-button{
    color: #919191;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    padding-right: 5px;
    padding-bottom: 2px;
    &::before{
        position: absolute;
        content: "";
        background: #919191;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
    }
    &:hover{
        color: #009DFF;
    }
    &:hover::before{
        background: #009DFF;
    }
}
.book_now{
    display: inline-block;
    font-size: 14px;
    color: #009DFF;
    border: 1px solid #009DFF;
    text-transform: capitalize;
    padding: 10px 25px;
    &:hover{
        background: #009DFF;
        color: #fff;
    }
}


