.team_area{
    padding-bottom: 200px;
    padding-top: 125px;
    @media #{$mobile_device} {
        padding-bottom: 60px;
        padding-top: 50px;
    }
    @media #{$tablet_device} {
        padding-bottom: 100px;
        padding-top: 50px;
    }
    .single_team{
        margin-bottom: 30px;
        .thumb{
            img{
                width: 100%;
            }
        }
        .member_name{
            position: relative;
            @include transform(translateY(-20px));
            @include transition(.3s);
            .mamber_inner{
                border-radius: 5px;
                display: inline-block;
                padding: 16px 40px 11px 40px;
                background-color: rgb(255, 255, 255);
                box-shadow: 0px 16px 35px 0px rgba(0, 0, 2, 0.08);
                h4{
                    font-family: $font2;
                    font-weight: 700;
                    color: #222222;
                    margin-bottom: 0;
                }
                p{
                    font-size: 14px;
                    color: #7a7b7c;
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }
        }
        &:hover{
            .member_name{
                @include transform(translateY(-25px));
            }
        }
    }
}
