
@import 'varriable.scss';
@import 'mixins.scss';
@import 'extend';
@import 'responsive.scss';
@import 'reset';
@import 'btn';
@import 'section_title';
@import 'slick-nav.scss';



// header
@import 'header';

// slider
@import 'slider';

// about
@import 'about';

// prising
@import 'prising';

// service
@import 'service';

// _team
@import 'team';

// make_apointment
@import 'make_apointment';

// _team
@import '_testmonial';

// _team
@import '_gallery';

// about
@import 'footer';

@import 'bradcam';


@import 'pet_care';

@import '_adapt';

@import 'anipat';

// other pages default

// contact
@import 'contact';

// elements
@import 'elements.scss';

// blog
@import 'blog';


// blog part
@import 'blog_part';

