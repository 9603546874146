.breadcam_bg{
    background-image: url(../img/banner/bradcam.png);
}
.breadcam_bg_1{
    background-image: url(../img/banner/bradcam2.png);
}
.breadcam_bg_2{
    background-image: url(../img/banner/bradcam3.png);
}
.bradcam_area{
    background-size: cover;
    background-position: center center;
    padding: 160px 0;
    background-position: bottom;
    background-repeat: no-repeat;
    @media #{$mobile_device} {
        padding: 150px 0;
    }
    @media #{$tablet_device} {
        padding: 150px 0;
    }
    h3{
        font-size: 50px;
        color: #fff;
        font-weight: 900;
        margin-bottom: 0;
        font-family: $font1;
        text-transform: capitalize;
        @media #{$mobile_device} {
            font-size: 30px;
        }
        @media #{$tablet_device} {
            font-size: 40px;
        }
    }
}

.popup_box{
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%,-50%);
    background: #fff;
    display: inline-block;
    z-index: 9;
    width: 681px;
    padding: 60px 40px;
    @media #{$mobile_device} {
        width: 320px;
        padding: 45px 30px;
    }
    @media #{$large_mobile} {
        width: 420px !important;
        padding: 45px 30px;
    }
    
    h3{
        text-align: center;
        font-size: 22px;
        color: #1F1F1F;
        margin-bottom: 46px;
    }
    .boxed-btn3{
        width: 100%;
        text-transform: capitalize;
    }
    .nice-select {
        -webkit-tap-highlight-color: transparent;
        background-color: #fff;
        /* border-radius: 5px; */
        border: solid 1px #E2E2E2;
        box-sizing: border-box;
        clear: both;
        cursor: pointer;
        display: block;
        float: left;
        font-family: $font2;
        font-weight: normal;
        width: 100% !important;
        /* height: 42px; */
        line-height: 50px;
        outline: none;
        padding-left: 18px;
        padding-right: 30px;
        position: relative;
        text-align: left !important;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        white-space: nowrap;
        width: auto;
        border-radius: 0;
        margin-bottom: 30px;
        height: 50px !important;
        font-size: 16px;
        font-weight: 400;
        color: #919191;
        &::after {
            content: "\f0d7";
            display: block;
            height: 5px;
            margin-top: -5px;
            pointer-events: none;
            position: absolute;
            right: 17px;
            top: 3px;
            transition: all 0.15s ease-in-out;
            width: 5px;
            font-family: fontawesome;
            color: #919191;
            font-size: 15px;
        }
        &.open .list {
            opacity: 1;
            pointer-events: auto;
            -webkit-transform: scale(1) translateY(0);
            -ms-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
            height: 200px;
            overflow-y: scroll;
        }
        &.list {
            height: 200px;
            overflow-y: scroll;
        }
    }
}
#test-form {
    display: inline-block;
    margin: auto;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    @include transform (translate(-50%,-50%));
    @media #{$mobile_device}{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include transform (none);
    }
    .mfp-close-btn-in .mfp-close {
        color: #333;
        display: none !important;
    }
    button{
        &.mfp-close{
            display: none !important;
            @media #{$mobile_device} {
                display: block !important;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
    button.mfp-close {
        overflow: visible;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        display: block;
        outline: none;
        padding: 0;
        z-index: 1046;
        box-shadow: none;
        touch-action: manipulation;
        width: 40px;
        height: 40px;
        background: #4A3600;
        text-align: center;
        line-height: 20px;
        position: absolute;
        right: 0;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        position: absolute;
        right: -6px;
        color: #fff !important;
    }
}

.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #4A3600;
	opacity: 0.6;
}

.gj-picker.gj-picker-md.datepicker.gj-unselectable {
    @media #{$mobile_device} {
        // width: 320px;
        // left: 0 !important;
    }

}
.gj-picker.gj-picker-md.timepicker {
    @media #{$mobile_device} {
        width: 310px;
        left: 6px !important;
    }

}
.gj-picker.gj-picker-md.datepicker.gj-unselectable {
    @media #{$mobile_device} {
        width: 320px;
        left: 0 !important;
    }
}