.anipat_bg_1{
    background-image: url(../img/banner/contact_bg.png);
}

.contact_anipat{
    background-position: center center;
    background-size: cover;
    padding-top: 170px;
    padding-bottom: 160px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    @media #{$mobile_device} {
       background-size: cover;
       padding: 110px 0;
    }
    .contact_text{
        .section_title{
            h3{
                color: #fff;
            }
            p{
                font-size: 25px;
                color: #fff;
                line-height: 40px;
                margin-top: 35px;
                margin-bottom: 50px;
            }

        }
        .contact_btn{
            @media #{$mobile_device} {
                display:  block !important;
            }
            p{
                margin-bottom: 0;
                font-size: 18px;
                font-weight: 400;
                font-family: $font1;
                color: #fff;
                margin-left: 20px;
                @media #{$mobile_device} {
                    margin-left: 0;
                    margin-top: 30px;
                }
                a{
                    font-weight: 600;
                    color: #fff;
                    margin-left: 10px;
                }
            }
        }
    }
}