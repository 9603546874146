.header-area{
    // position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    z-index: 9;
    // padding-top: 28px;
    @media #{$mobile_device} {
        padding-top: 0;
    }
    @media #{$tablet_device} {
        padding-top: 0;
    }
    .main-header-area{
        // padding: 18px 0;
        background: #fff;
        // padding: 0 150px;
        @media #{$mobile_device} {
            padding: 10px 10px;
        }
        @media #{$tablet_device} {
            padding: 10px 10px;
        }
        // @media #{$mid_device} {
        //     padding: 0 20px;
        // }
        // @media #{$large_device} {
        //     padding: 0 10px;
        // }
        .logo-img{
            text-align: center;
            @media #{$mobile_device} {
                // padding-left: 20px;
                text-align: left;
            }
            @media #{$tablet_device} {
                // padding-left: 20px;
                text-align: left;
            }
            @media #{$mid_device} {
                // padding-left: 20px;
                text-align: left;
            }
            img{
                @media #{$mobile_device} {
                    // padding-left: 20px;
                    width: 70px;
                }
                @media #{$tablet_device} {
                    // padding-left: 20px;
                    width: 70px;
                }
               
            }
        }
        .Appointment{
            @include flexbox();
            @include align-items(center);
            @include justify-content(flex-end);
           .socail_links{
               ul{
                   li{
                       display: inline-block;

                       a{
                        color: #A8A7A0;
                        margin: 0 10px;
                        font-size: 15px;
                        &:hover{
                            color: #fff;
                        }
                       }
                   }
               }
           }
           .book_btn{
               margin-left: 30px;
               @media #{$mid_device} {
                   margin-left: 0;
               }
               @media #{$large_device} {
                   margin-left: 0;
               }
               a{
                background: #009DFF;
                padding: 12px 26px;
                font-size: 14px;
                font-weight: 400;
                border: 1px solid transparent;
                color: #fff;
                @include border-radius(4px);
                @media #{$mid_device} {
                    padding: 12px 20px;
                }
                &:hover{
                    background: #fff;
                    color: #009DFF;
                    border: 1px solid #009DFF;
                }
               }
           }
        }
        .main-menu{
            text-align: right;
            // padding: 12px 0;
            ul{
                li{
                    display: inline-block;
                    position: relative;
                    margin-left: 40px;
                    // margin: 0 10px;
                    // @media #{$mid_device} {
                    //     margin-right: 20px;
                    // }
                    // @media #{$large_device} {
                    //     margin-right: 15px;
                    // }
                    a{
                        color: #102955;
                        font-size: 16px;
                        text-transform: capitalize;
                        font-weight: 500;
                        display: inline-block;
                        padding: 37px 0;
                        font-family: $font1;
                        position: relative;
                        text-transform:capitalize;
                        
                        @media #{$mid_device} {
                            // padding: 41px 0px 10px 0px;
                            font-size: 15px;
                        }
                        @media #{$large_device} {
                            // padding: 41px 0px 10px 0px;
                            font-size: 15px;
                        }
                        i{
                            font-size: 9px;
                            @media #{$mobile_device}{
                                display: none !important;
                            }
                            @media #{$tablet_device}{
                                display: none !important;;
                            }
                        }
                        &::before {
                            position: absolute;
                            content: "";
                            background: #fff;
                            width: 100%;
                            height: 2px;
                            bottom: 0;
                            left: 0;
                            opacity: 0;
                            transform: scaleX(0);
                            @include transition(.3s);
                        }
                        &:hover::before{
                            opacity: 1;
                            transform: scaleX(1);
                        }
                        &.active{
                            &::before{
                                opacity: 1;
                                transform: scaleX(1);
                            }
                        }
                    }
                    &:hover > a{
                        color: $btn-bg !important;
                    }
                    .submenu {
                        position: absolute;
                        left: 0;
                        top: 120%;
                        background: #fff;
                        width: 200px;
                        z-index: 9;
                        box-shadow: 0 0  10px rgba(0,0,0,.02);
                        opacity: 0;
                        visibility: hidden;
                        text-align: left;
                        @include transition(.6s);
                        
                        text-align: left;
                        li{
                            display: block;
                            margin-left: 0;
                            a{
                                padding: 10px 15px;
                                position: inherit;
                                @include transition(.3s);
                                display: block;
                                color: #000;
                                &::before{
                                    display: none;
                                }
                            }
                            &:hover a{
                                color: #000;
                            }
                        }
                    }
                    &:hover > .submenu{
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                    &:hover > a::before{
                        opacity: 1;
                        transform: scaleX(1);
                    }
                    &:first-child a {
                        padding-left: 0;
                    }
                }
            }
        }
        &.sticky {
            box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
            position: fixed;
            width: 100%;
            top: -70px;
            left: 0;
            right: 0;
            z-index: 99;
            transform: translateY(70px);
            transition: transform 500ms ease, background 500ms ease;
            -webkit-transition: transform 500ms ease, background 500ms ease;
            box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
            // padding: 10px 150px;
            background: rgba(255, 255, 255,.96);
            @media #{$mobile_device} {
                padding: 10px 10px;
            }
            @media #{$tablet_device} {
                padding: 10px 10px;
            }
            @media #{$mid_device} {
                padding: 10px 20px;
            }
            @media #{$large_device} {
                padding: 10px 20px
            }
            .main-menu{
                padding: 0;
            }
        }
        
    }
    .header-top_area{
        padding: 19px 0;
        background: #1c1818;
      .social_media_links{
          text-align: right;
          @media #{$mobile_device} {
              text-align: center;
          }
          a{
            font-size: 18px;
            color: #C7C7C7;
            margin-left: 32px;
            &:hover{
                color: $btn-bg;
            }
          }
      }  
      .short_contact_list{
          text-align: left;
          @media #{$mobile_device} {
            text-align: center;
        }
          ul{
              li{
                  display: inline-block;
                  a{
                    font-size: 16px;
                    color: #ffffff;
                    font-family: $font1;
                    font-weight: 400;
                    margin-right: 50px; 
                    @media #{$mobile_device} 
                    {
                        margin-left: 0;
                        margin-right: 5px;
                        font-size: 14px;
                        margin-bottom: 10px;
                        display: inline-block;
                    }
                  }
              }
          }
      }
    }
}